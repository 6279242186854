import React from 'react'
import styled from 'styled-components'
import { Link as HistoryLink } from 'react-router-dom'
import { ArrowLeft } from 'react-feather'
import { RowBetween } from 'components/Row'
import QuestionHelper from 'components/QuestionHelper'
import useI18n from 'hooks/useI18n'

const Tabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-radius: 3rem;
  justify-content: space-evenly;
`

const ActiveText = styled.div`
  font-weight: 500;
  font-size: 20px;
`

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${({ theme }) => theme.colors.text};
`

export function FindPoolTabs() {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>Tìm Quỹ TK</ActiveText>
        <QuestionHelper
          text={TranslateString(256, 'Sử dụng công cụ này để tìm quỹ thanh khoản bạn đã tham gia nếu hệ thống không tự động hiển thị.')}
        />
      </RowBetween>
    </Tabs>
  )
}

export function AddRemoveTabs({ adding }: { adding: boolean }) {
  const TranslateString = useI18n()
  return (
    <Tabs>
      <RowBetween style={{ padding: '1rem' }}>
        <HistoryLink to="/pool">
          <StyledArrowLeft />
        </HistoryLink>
        <ActiveText>{adding ? TranslateString(258, 'Thêm Vốn') : TranslateString(260, 'Rút Vốn')} Liquidity</ActiveText>
        <QuestionHelper
          text={
            adding
              ? TranslateString(
                  264,
                  'Khi bạn tạo hoặc tham gia vào một quỹ thanh khoản, bạn sẽ nhận được token VinaSwap Thanh Khoản (VNS-TK) tương ứng với cổ phần của bạn. Những token này sẽ tự động kiếm tiền từ phí giao dịch trên sàn. Bạn có thể rút vốn và lãi bất kỳ lúc nào.'
                )
              : TranslateString(
                  266,
                  'Khi rút khỏi quỹ thanh khoản bạn sẽ phải trả lại token VinaSwap Thanh Khoản (VNS-TK) để đổi lấy cặp token bạn đã đầu tư. Số tiền bạn rút là tổng vốn và lãi, lãi nhận được tương ứng với số cổ phần bạn nắm trong quỹ.'
                )
          }
        />
      </RowBetween>
    </Tabs>
  )
}
