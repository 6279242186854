import { Percent } from '@pancakeswap-libs/sdk'
import { ALLOWED_PRICE_IMPACT_HIGH, PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN } from '../../constants'

/**
 * Given the price impact, get user confirmation.
 *
 * @param priceImpactWithoutFee price impact of the trade without the fee.
 */
export default function confirmPriceImpactWithoutFee(priceImpactWithoutFee: Percent): boolean {
  if (!priceImpactWithoutFee.lessThan(PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN)) {
    return (
      window.prompt(
        `Giao dịch này sẽ làm biến độn giá ít nhất ${PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN.toFixed(
          0
        )}%. Vui lòng nhập "XAC NHAN" để tiếp tục.`
      ) === 'XAC NHAN'
    )
  } if (!priceImpactWithoutFee.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) {
    return window.confirm(
      `Giao dịch này sẽ làm biến độn giá ít nhất ${ALLOWED_PRICE_IMPACT_HIGH.toFixed(
        0
      )}%. Bạn có chắc là muốn tiếp tục giao dịch không?`
    )
  }
  return true
}
