import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Trang Chủ',
    icon: 'HomeIcon',
    href: 'https://vinaswap.com',
  },
  {
    label: 'Giao Dịch',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Giao Dịch',
        href: '/swap',
      },
      {
        label: 'Thanh Khoản',
        href: '/pool',
      },
    ],
  },
  {
    label: 'Mua VNS',
    icon: 'InfoIcon',
    href: 'https://vinaswap.com/#/swap?outputCurrency=0x09592D637108adFF430C59a24C442abCe955F142',
  },
  {
    label: 'Vina ICO',
    icon: 'InfoIcon',
    href: 'https://vinaswap.com/ico',
  },
  {
     label: 'Vina IDO',
     icon: 'GroupsIcon',
     href: 'https://vinaswap.com/ido',
  },
  {
     label: 'ĐK Coin Lên Sàn',
      icon: 'TicketIcon',
     href:  'https://vinaswap.com/register-coin',
  },
  // {
  //   label: 'Farms',
  //   icon: 'FarmIcon',
  //   href: 'https://vinaswap.com/farms',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: 'https://vinaswap.com/syrup',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://vinaswap.com/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: 'https://vinaswap.com/nft',
  // },
  // {
  //   label: 'Teams & Profile',
  //   icon: 'GroupsIcon',
  //   calloutClass: 'rainbow',
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: 'https://vinaswap.com/teams',
  //     },
  //     {
  //       label: 'Task Center',
  //       href: 'https://vinaswap.com/profile/tasks',
  //     },
  //     {
  //       label: 'Your Profile',
  //       href: 'https://vinaswap.com/profile',
  //     },
  //   ],
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://info-swap.phathanhcoin.com',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://info-swap.phathanhcoin.com/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://info-swap.phathanhcoin.com/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://info-swap.phathanhcoin.com/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: 'IFO',
  //   icon: 'IfoIcon',
  //   href: 'https://vinaswap.com/ifo',
  // },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'Voting',
  //       href: 'https://voting.swap.phathanhcoin.com',
  //     },
  //     {
  //       label: 'Github',
  //       href: 'https://github.com/phathanhcoin',
  //     },
  //     {
  //       label: 'Docs',
  //       href: 'https://vinaswap.com/docs',
  //     },
  //     {
  //       label: 'Blog',
  //       href: 'https://phathanhcoin.com/blog',
  //     },
  //   ],
  // },
]

export default config
